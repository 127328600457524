.custom-file {
  width: 400px;

  .custom-file-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right:75px;
  }
}
