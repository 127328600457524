#public-document {
    .company-name {
        font-family: "BroadwayRegular", serif;
        color: #4069AF;
        transform: scaleY(2);
    }

    .file-table-td {
        border: 2px solid black;
    }

    .document-code {
        font-family: "Arial Rounded MT Bold", serif;
    }

}
