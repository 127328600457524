#document-container {
  .document-table {
    table > tbody > tr > td {
      vertical-align: middle;
      padding: 0.3rem;
    }

    table > thead > tr > th {
      font-weight: 500;
    }
  }

  .document-code {
    font-family: "Arial Rounded MT Bold", serif;
    font-size: 1.95rem;
  }
}
