#login {
    .view {
        background-size: cover;
        height: 100vh;
    }

    .card {
        background-color: rgba(229, 228, 255, 0.2);
    }

    h6 {
        line-height: 1.7;
    }

    .company-name {
        font-family: "BroadwayRegular", serif;
        color: #4069AF;
        transform: scaleX(0.61);
    }

}
